import { Component } from '@angular/core';

@Component({
  selector: 'hiji-satisfaction-thanks',
  templateUrl: './satisfaction-thanks.component.html',
  styleUrls: ['./satisfaction-thanks.component.scss']
})
export class SatisfactionThanksComponent {

}
