import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Ic2Module } from '@ic2/ic2-lib';
import { TranslateModule } from '@ngx-translate/core';
import { HijiCommonModule, TRANSLATE_CHILD_CONFIG } from 'common';
import { ObservableGestureModule } from 'projects/frontoffice/src/app/components/observable-gesture.module';
import { SatisfactionAnswerComponent } from './satisfaction-answer/satisfaction-answer.component';
import { SatisfactionThanksComponent } from './satisfaction-thanks/satisfaction-thanks.component';

@NgModule({
  declarations: [SatisfactionAnswerComponent, SatisfactionThanksComponent],
  imports: [CommonModule, HijiCommonModule, Ic2Module, ObservableGestureModule, TranslateModule.forChild(TRANSLATE_CHILD_CONFIG)],
  exports: [SatisfactionAnswerComponent, SatisfactionThanksComponent],
})
export class MifSatisfactionModule {}
//TODO rename MIFSatisfactionModule
