import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { L, MsPipe, MultilingualString } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import {
  AbstractViewAddEdit,
  AuthService,
  CriteriaType,
  FormationObservationObservableGestureDTO,
  FormationSessionExpectationsService,
  FormationSessionParticipantDTO,
  FormationSessionParticipantExpectation,
  FormationSessionParticipantExpectationsDTO,
  FormationSessionSatisfactionService,
  Ic2ToastrService,
  SatisfactionRepositoryAnswerDTO,
  TagManager,
  TagRadarData,
  getValuesForCriteria,
} from 'common';
import { ObservationEvent } from 'projects/frontoffice/src/app/components/observable-gesture-evaluation/observable-gesture-evaluation.component';
import { AnswerModel } from 'projects/frontoffice/src/app/components/observable-gesture-result/AnswerModel';
import { forkJoin, map, of } from 'rxjs';
import { MifParticipantDataService } from '../../mif.participant.data.service';

@Component({
  selector: 'hiji-satisfaction-answer',
  templateUrl: './satisfaction-answer.component.html',
  styleUrls: ['./satisfaction-answer.component.scss'],
})
export class SatisfactionAnswerComponent implements OnInit {
  @Input()
  view: boolean = false;
  idFormationSession: number = 0;
  idUser: number = 0;
  data: SatisfactionRepositoryAnswerDTO;
  loading: boolean = true;
  loadingSave: boolean = false;
  dataParticipant: FormationSessionParticipantDTO = null;
  alreadyAnswer: boolean = false;
  expectations: FormationSessionParticipantExpectationsDTO = null;
  radarData: TagRadarData[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private formationSessionSatisfactionService: FormationSessionSatisfactionService,
    private formationSessionExpectationsService: FormationSessionExpectationsService,
    private ic2ToastrService: Ic2ToastrService,
    private translate: TranslateService,
    private router: Router,
    private mifParticipantDataService: MifParticipantDataService,
    private tagManager: TagManager,
    private msPipe: MsPipe
  ) {}

  ngOnInit(): void {
    this.loading = true;
    let promise = null;
    if (this.authService.userBundle === null) {
      this.idFormationSession = this.activatedRoute.snapshot.params.idFormationSession;
      promise = Promise.resolve();
    } else {
      this.idUser = AbstractViewAddEdit.getClosestRouteParam(this.activatedRoute, 'idUser');
      if (this.idUser === null) this.idUser = this.authService.userBundle.user.idUser;
      this.idFormationSession = +AbstractViewAddEdit.getClosestRouteParam(this.activatedRoute, 'idFormationSession');
      promise = this.mifParticipantDataService
        .load(this.idFormationSession, this.idUser)
        .then(() => {
          this.dataParticipant = this.mifParticipantDataService.dataParticipant;
          this.alreadyAnswer = this.dataParticipant.satisfactionDone;
        })
        .catch((err) => {
          L.e(err);
          this.ic2ToastrService.showDanger(this.translate.instant('global.Une erreur est survenue'));
        });
    }

    Promise.all([promise, this.tagManager.load()]).then(() => {
      forkJoin([this.loadSatisfactionRepository(), this.loadExpectations()]).subscribe({
        next: (data) => {
          if (this.view) this.buildRadarData();
          this.loading = false;
        },
        error: (err) => {
          this.erreurNavigateAndToast(err);
        },
      });
    });
  }

  loadSatisfactionRepository() {
    return this.formationSessionSatisfactionService
      .getSatisfactionParticipant(this.idFormationSession, this.idUser)
      .toObservable()
      .pipe(
        map((data: SatisfactionRepositoryAnswerDTO) => {
          this.data = data;
        })
      );
  }

  loadExpectations() {
    if (this.authService.userBundle === null || (this.dataParticipant !== null && !this.dataParticipant.expectationsParticipantEvaluationEnabled))
      return of(true);
    return this.formationSessionExpectationsService
      .getExpectations(this.idUser, this.idFormationSession)
      .toObservable()
      .pipe(
        map((data) => {
          this.expectations = data;
        })
      );
  }

  getCriteria(idCriteria: number) {
    return this.data.criterias.find((cr) => cr.idCriteria === idCriteria);
  }

  observe(event: ObservationEvent, og: FormationObservationObservableGestureDTO) {
    og.observed = event.observed;
    og.value = event.value;
    og.answerDate = new Date();
  }

  observeText(event: ObservationEvent, og: FormationObservationObservableGestureDTO) {
    og.observed = event.observed;
    og.text = event.text;
    og.answerDate = new Date();
  }

  save() {
    this.loadingSave = true;

    forkJoin([this.formationSessionSatisfactionService.saveParticipantSatisfaction(this.data).exec(), this.saveExpectations()]).subscribe({
      next: (data) => {
        this.alreadyAnswer = true;
        this.loadingSave = false;
        if (this.router.url.includes('out/formation')) {
          this.router.navigate(['thanks'], { relativeTo: this.activatedRoute });
        } else {
          this.ic2ToastrService.showSuccess(this.translate.instant('global.Bien enregistré'));
          this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
        }
      },
      error: (err) => {
        if (err && err.data && err.data['code'] === 'already_answer') {
          this.ic2ToastrService.showDanger(this.translate.instant('mif.satisfaction.answer.Vous avez déjà répondu'));
          this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
        } else {
          this.erreurNavigateAndToast(err);
        }
      },
    });
  }

  getMyExpectations() {
    return this.expectations.expectations.filter((e) => e.idUser === e.idUserCreator);
  }

  saveExpectations() {
    if (
      this.authService.userBundle === null ||
      (this.expectations !== null && !this.expectations.expectationsParticipantEnabled) ||
      !this.dataParticipant.expectationsParticipantEvaluationEnabled
    )
      return of(true);
    return this.formationSessionExpectationsService.saveSessionExpectations(this.expectations).exec();
  }

  erreurNavigateAndToast(err) {
    L.e(err);
    this.ic2ToastrService.showDanger(this.translate.instant('global.Une erreur est survenue'));
    this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
  }

  getExpectationAsOG(expectation: FormationSessionParticipantExpectation) {
    return {
      idObservableGesture: expectation.idFormationSessionParticipantExpectation,
      observableGestureName: new MultilingualString({ [this.translate.currentLang]: expectation.expectation }),
      observableGestureDescription: new MultilingualString(),
      value: 0,
      text: '',
      observed: false,
      answerDate: new Date(),
      highlight: null,
      tags: [],
    };
  }

  computeAnswers(expectation: FormationSessionParticipantExpectation): AnswerModel[] {
    const criteria = this.expectations.criteriaExpectations;
    if (criteria.type === CriteriaType.QUESTION_OUVERTE) return [];

    let values = getValuesForCriteria(criteria);

    return values.map((val) => {
      return {
        value: val,
        answers: [
          {
            name: this.translate.instant('mif.Satisfaction des attentes'),
            color: 'var(--hiji-auto-observation)',
            nb: expectation.observed && expectation.note === val ? 1 : 0,
          },
        ],
      } as AnswerModel;
    });
  }

  observeCriteria(note, expectation: FormationSessionParticipantExpectation) {
    if (this.view) return;
    // j45 a voir encore
    if (expectation.note === note) {
      expectation.note = 0;
      expectation.observed = false;
    } else {
      expectation.note = note;
      expectation.observed = true;
    }
  }

  buildRadarData() {
    let tags = this.data.oogs
      .filter((og) => og.criteriaType !== CriteriaType.QUESTION_OUVERTE)
      .reduce((acc, og) => {
        og.tags.forEach((tag) => {
          if (acc.find((t) => t.idTag === tag.idTag) === undefined) {
            let t = this.tagManager.getTag(tag.idTag);
            acc.push({
              name: t.name,
              idTag: tag.idTag,
              colorCode: t.colorCode,
              sum: og.value ? og.value : 0,
              nbAnswers: og.observed ? 1 : 0,
            });
          } else {
            let t = acc.find((t) => t.idTag === tag.idTag);
            if (og.observed) {
              t.nbAnswers++;
              t.sum += og.value;
            }
          }
        });
        return acc;
      }, []);

    this.radarData = [
      {
        name: 'Satisfaction',
        color: 'var(--hiji-auto-observation)',
        background: true,
        values: tags.map((tag) => {
          return {
            axis: this.msPipe.transform(tag.name),
            color: tag.colorCode,
            value: tag.nbAnswers === 0 ? 0 : tag.sum / tag.nbAnswers, //avg
            data: tag.idTag,
          };
        }),
      } as TagRadarData,
    ];
  }
}
